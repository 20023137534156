import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Cookies from 'js-cookie'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: function() {
            return import ('../views/Login.vue')
        }
    },
    {
        path: '/rateDetail',
        name: 'RateDetail',
        component: function() {
            return import ('../views/RateDetail.vue')
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: function() {
            return import ('../views/Settings.vue')
        }
    },
    {
        path: '/kpi',
        name: 'Kpi',
        component: function() {
            return import ('../views/Kpi.vue')
        }
    },
    {
        path: '/manualSync',
        name: 'ManualSync',
        component: function() {
            return import ('../views/ManualSync.vue')
        }
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    let tokenStr = Cookies.get('otoken');
    let roles = '';
    if (tokenStr) {
        roles = JSON.parse(tokenStr).roles;
    }
    if (!tokenStr && (to.path != '/login')) {
        next({ path: '/login' })
    } else if ((to.path == '/kpi' && roles.indexOf('jxgl')<0) || (to.path == '/settings' && roles.indexOf('xmpz')<0) || (to.path == '/manualSync' && roles.indexOf('sdtb')<0)
        || (to.path == '/login' && tokenStr)) {
        next({ path: '/' })
    } else {
        next();
    }
});

export default router