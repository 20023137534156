import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import {Divider,Tooltip,Spin,Table,Form,FormItem,Input,Icon,Button,Message} from 'view-design';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
// Vue.component('Divider', Divider);
// Vue.component('Tooltip', Tooltip);
// Vue.component('Spin', Spin);
// Vue.component('Table', Table);
// Vue.component('Form', Form);
// Vue.component('FormItem', FormItem);
// Vue.component('Input', Input);
// Vue.component('Icon', Icon);
// Vue.component('Button', Button);
// Vue.component('Message', Message);
Vue.use(ViewUI)
import httpUtil from './utils/httpUtil'
Vue.prototype.$http=httpUtil
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
import Cookies from 'js-cookie'
Vue.prototype.$cookie=Cookies
import commonUtil from './utils/commonUtil'
Vue.prototype.$common=commonUtil

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

Date.prototype.Format = function (fmt) {
  var o = {
      "M+": this.getMonth() + 1, //月份
      "d+": this.getDate(), //日
      "h+": this.getHours(), //小时
      "m+": this.getMinutes(), //分
      "s+": this.getSeconds(), //秒
      "q+": Math.floor((this.getMonth() + 3) / 3), //季度
      "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
};

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
