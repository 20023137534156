const getDaysBetween = function(dateString1,dateString2){
    var  startDate = Date.parse(dateString1?dateString1:new Date().Format("yyyy-MM-dd"));
    var  endDate = Date.parse(dateString2?dateString2:new Date().Format("yyyy-MM-dd"));
    var days=(endDate - startDate)/(1*24*60*60*1000);
    return  days;
}

const exportBillingExcel = function (data, res) {
    if (!data) {//如果没有data数据就不进行操作
        return
    }
    // 获取headers中的filename文件名
    let fileName = res.headers['content-disposition'].split(';')[1].split('filename=')[1]
    fileName = decodeURI(fileName);
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' })//转换成二进制对象
    if ('download' in document.createElement('a')) { // 不是IE浏览器
        let url = window.URL.createObjectURL(blob)//二进制对象转换成url地址
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // 下载完成移除元素
        window.URL.revokeObjectURL(url) // 释放掉blob对象
    } else {
        window.navigator.msSaveBlob(blob, fileName)
    }
}

export default{
    getDaysBetween,
    exportBillingExcel
}