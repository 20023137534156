import axios from 'axios';
import router from '../router'
import Cookies from 'js-cookie'
import { Modal, Message } from 'view-design'

axios.defaults.baseURL = '/api';
// axios.defaults.timeout = 30000

axios.interceptors.request.use(
    config => {
        try {
            const token = Cookies.get('otoken')
            token && (config.headers['otoken'] = token)
            return config
        } catch (error) {
            console.log({ error })
        }
    },
    error => {
        return Promise.error({ error })
    }
)

axios.interceptors.response.use(
    response => {
        //导出excel
        if (response.status === 200 && response.config.responseType == 'blob') {
            if (response.data.data === null) {
                Message.error("下载失败，请联系管理员");
            } else {
                return Promise.resolve(response)
            }
        }
        //其他请求
        if (response.status === 200 && response.data.result === '000') {
            return Promise.resolve(response.data.data)
        } else if (response.data.result === '4001') {
            Modal.warning({
                title: '提示',
                content: '请求Token信息已失效，请重新登录',
                okText: '重新登录',
                onOk: () => {
                    Cookies.remove('otoken');
                    location.reload()
                }
            });
            return Promise.reject(response)
        } else if (response.data.result === '4002') {
            // 登录手机号未授权
            Message.error(response.data.desc);
            return Promise.reject(response)
        } else {
            Message.error(response.data.desc);
            return Promise.reject(response)
        }
    },
    error => {
        return Promise.reject(error);
    }
)

export const get = (url, data) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: data
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject({ err })
        })
    })
}

export const post = (url, data) => {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(res => {
            resolve(res);
        }).catch(err => {
            reject({ err })
        })
    });
}

export const remove = (url, data) => {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
            params: data
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject({ err })
        })
    })
}

export const exportExcel = (url, data) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: url,
            data: data,
            responseType: 'blob'
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject({ err })
        })
    })
}

export default {
    get,
    post,
    remove,
    exportExcel
}