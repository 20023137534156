<template>
	<div class="container">
		<Divider plain orientation="left">项目进度表</Divider>
		<div class="content">
			<div class="tb-box">
				<div class="tb-row header">
					<span class="tb-col" :title="item.title" :style="`flex:${item.grow}`" v-for="(item,index) in columns1">{{item.title}}</span>
				</div>
				<div class="loading-box" v-if="loading1">
					<Spin size="large" fix></Spin>
				</div>
				<vue-seamless-scroll class="warp" :data="data1" :class-option="option" @ScrollEnd="ScrollEnd" ref="seamlessScroll1" v-if="!loading1&&data1.length>0">
					<div :class="`${item.alertMode==1?'alert-row':item.alertMode==0?'specific-alert-row':''} tb-row`" v-for="(item,index) in data1">
						<span class="tb-col" :title="index+1" style="flex:4">{{index+1}}</span>
						<span class="tb-col" :title="item.creator" style="flex:5">{{item.creator}}</span>
						<span class="tb-col" :title="item.projectName" style="flex:13">{{item.projectName}}</span>
						<span class="tb-col" :title="item.signingDate">{{item.signingDate}}</span>
						<span class="tb-col" :title="item.plannedDate">{{item.plannedDate}}</span>
						<span class="tb-col" :title="item.filingDate">{{item.filingDate}}</span>
						<span class="tb-col" :title="item.startDate">{{item.startDate}}</span>
						<span class="tb-col" :title="item.basicAcceptDate">{{item.basicAcceptDate}}</span>
						<span class="tb-col" :title="item.steelStructureAcceptDate">{{item.steelStructureAcceptDate}}</span>
						<span class="tb-col" :title="item.wellDecorationDate">{{item.wellDecorationDate}}</span>
						<span class="tb-col" :title="item.handoverDate">{{item.handoverDate}}</span>
						<span class="tb-col" :title="item.overdueStage">{{item.overdueStage}}</span>
						<span class="tb-col" :title="item.overdueDays">{{item.overdueDays>0?item.overdueDays:''}}</span>
					</div>
				</vue-seamless-scroll>
				<div class="empty" v-if="!loading1&&data1.length==0">暂无数据</div>
			</div>
		</div>
		
		
		<Divider plain orientation="left">项目回款率</Divider>
		<div class="content">
			<div class="tb-box">
				<div class="tb-row header">
					<span class="tb-col" :title="item.title" :style="`flex:${item.grow}`" v-for="(item,index) in columns2">{{item.title}}</span>
				</div>
				<div class="loading-box" v-if="loading2">
					<Spin size="large" fix></Spin>
				</div>
				<vue-seamless-scroll class="warp" :data="data2" :class-option="option" ref="seamlessScroll2" v-if="!loading2&&data2.length>0">
					<div :class="`${item.isAlert?'alert-row':''} tb-row`" v-for="(item,index) in data2">
						<span class="tb-col" :title="index+1" style="flex:4">{{index+1}}</span>
						<span class="tb-col" :title="item.creator" style="flex:5">{{item.creator}}</span>
						<span class="tb-col" :title="item.projectName" style="flex:14">{{item.projectName}}</span>
						<span class="tb-col" :title="item.signingDate">{{item.signingDate}}</span>
						<span class="tb-col" :title="item.currentStage">{{item.currentStage}}</span>
						<span class="tb-col" :title="item.collectionRate">{{item.collectionRate}}</span>
						<span class="tb-col" :title="item.handoverDate">{{item.handoverDate}}</span>
					</div>
				</vue-seamless-scroll>
				<div class="empty" v-if="!loading2&&data2.length==0">暂无数据</div>
			</div>
		</div>

		
	</div>
</template>

<script>
export default {
	name: 'Home',
	data () {
      return {
        option:{
			step: 1, // 数值越大速度滚动越快
			limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
			hoverStop: true, // 是否开启鼠标悬停stop
			direction: 1, // 0向下 1向上 2向左 3向右
			openWatch: true, // 开启数据实时监控刷新dom
			singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
			singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
			waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
		},
		loading1:true,
		loading2:true,
		columns1: [
					{
                        title: '序号',
                        type: 'index',
						align: 'center',
						width: 70,
						grow: 4                    
					},
                    {
                        title: '创建人',
                        key: 'creator',
						align: 'center',
						tooltip: true,
						grow: 5
                    },
                    {
                        title: '项目名称',
                        key: 'projectName',
						width: 130,
						align: 'center',
						tooltip: true,
						grow: 13
                    },
					{
                        title: '合同签订',
                        key: 'signingDate',
						width: 130,
						align: 'center',
						tooltip: true,
                    },
					{
                        title: '规划完成',
                        key: 'plannedDate',
						width: 130,
						align: 'center',
						tooltip: true,
                    },
					{
                        title: '备案完成',
                        key: 'filingDate',
						width: 130,
						align: 'center',
						tooltip: true,
                    },
					{
                        title: '开工',
                        key: 'startDate',
						width: 130,
						align: 'center',
						tooltip: true,
                    },
					{
                        title: '基础验收',
                        key: 'basicAcceptDate',
						width: 130,
						align: 'center',
						tooltip: true,
                    },
					{
                        title: '钢结构验收',
                        key: 'steelStructureAcceptDate',
						width: 130,
						align: 'center',
						tooltip: true,
                    },
					{
                        title: '井道外装潢',
                        key: 'wellDecorationDate',
						width: 130,
						align: 'center',
						tooltip: true,
                    },
					{
                        title: '整体移交',
                        key: 'handoverDate',
						width: 130,
						align: 'center',
						tooltip: true,
                    },
					{
                        title: '超期阶段',
                        key: 'overdueStage',
						width: 130,
						align: 'center',
						tooltip: true,
                    },
					{
                        title: '超期天数',
                        key: 'overdueDays',
						width: 100,
						align: 'center',
						tooltip: true,
                    }
                ],
		columns2: [
					{
                        title: '序号',
                        type: 'index',
						align: 'center',
						width: 70,
						grow: 4
                    },
					{
                        title: '创建人',
                        key: 'creator',
						align: 'center',
						tooltip: true,
						grow: 5
                    },
                    {
                        title: '项目名称',
                        key: 'projectName',
						width: 130,
						align: 'center',
						tooltip: true,
						grow: 14
                    },
                    {
                        title: '合同签订',
                        key: 'signingDate',
						width: 130,
						align: 'center',
						tooltip: true,
                    },
					{
                        title: '项目阶段',
                        key: 'currentStage',
						align: 'center',
						width: 130,
						tooltip: true
                    },
					{
                        title: '回款率',
                        key: 'collectionRate',
						align: 'center',
						tooltip: true
                    },
					{
                        title: '整体移交',
                        key: 'handoverDate',
						align: 'center',
						width: 130,
						tooltip: true
                    },
                ],
		data1: [],
		data2: []
      }
    },
	methods:{
		initData(){
			this.getData1();
			this.getData2();
		},
		getData1(){
			this.$http.get(`/dashboard/pjList`).then(rs=>{
				let warnItemNum=0;
				rs.forEach(item => {
					if(item.alertMode){
						warnItemNum++;
					}
				});
				//进度表滚动列表赋值
				this.data1 = this.data1.concat(rs);
				// this.$refs.seamlessScroll1.reset();
				this.loading1=false;

				//设置提醒数据
				this.$store.commit("setWarnItemNum",warnItemNum);
			})
		},

		getData2(){
			this.$http.get(`/dashboard/hklList`).then(rs=>{
				let alertNum=0;
				rs.forEach(item => {
					if(item.isAlert){
						alertNum++;
					}
				});
				//汇款率滚动列表赋值
				this.data2 = this.data2.concat(rs);
				// this.$refs.seamlessScroll2.reset();
				this.loading2=false;

				//设置提醒数据
				this.$store.commit("setAlertItemNum",alertNum);
			})
		},

		ScrollEnd: function(){
			console.log("ScrollEnd")
		}
	},
	computed:{
		
	},
	mounted(){
		let tokenStr = this.$cookie.get('otoken');
		if(tokenStr){
			let token = JSON.parse(tokenStr);
			let phone = token.phone;
			let roles = token.roles || '';
			this.$store.commit("setPhone", phone);
			this.$store.commit("setRoles",roles);
			this.initData();
		}
	}
}
</script>

<style scoped>
	.container{
		width: 100%;
		margin: 0 auto;
		padding: 24px 15px; 
		min-height: 280px;
		background: #fff;
	}
	.warp {
		height: 300px;
		overflow: hidden;
	}
	.empty{
		height: 150px;
		line-height: 150px;
		text-align: center;
	}
	.loading-box{
		width: inherit;
		height: 150px;
		position: relative;
	}
	.content{
		margin: 20px 0 40px 0;
		display: flex;
	}
	.tb-box{
		width: 100%;
	}
	.tb-row{
		height: 48px;
		box-sizing: border-box;
		border-bottom: 1px solid #e8eaec;
		white-space: nowrap;
		display: flex;
	}
	.header{
		font-weight: bold;
		background-color: #f8f8f9 !important;
	}
	.tb-row .tb-col{
		display: inline-block;
		flex: 10;
		height: 48px;
		line-height: 48px;
		padding: 0 12px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.tb-row:nth-child(odd){
		background-color: #ffffff;
	}
	.tb-row:nth-child(even){
		background-color: #f8f8f9;
	}
	.warn-row{
		background-color: #ffc578 !important;
		color: #ffffff;
	}
	.alert-row{
		background-color: #ff8178 !important;
		color: #ffffff;
	}
	.specific-alert-row{
		background-color: #cc5850 !important;
		color: #ffffff;
	}

</style>