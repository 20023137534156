<template>
  <div id="app">
	<router-view v-if="$route.path=='/login'"/>
	<Layout class="main-wrap" v-else>
		<Header class="header-bar">
			<!-- <Menu class="header-bar" mode="horizontal" theme="dark" > -->
				<div class="logo"></div>
				<div class="nav-box">
					<!-- <div class="nav-item">
						<Tooltip content="系统提醒" placement="bottom">
							<Badge :dot="warnItemNum>0||alertItemNum>0">
								<Icon type="md-notifications-outline" size="25" @click="showDrawer=true"/>
							</Badge>
						</Tooltip>
					</div> -->
					<div class="nav-item">
						<Tooltip content="退出登录" placement="bottom">
							<Icon type="md-exit" size="25" @click="logout"/>
						</Tooltip>
					</div>
				</div>
			<!-- </Menu> -->
		</Header>
		<Layout class="core-wrap">
			<div style="display: flex;align-items: baseline;justify-content: space-between;">
				<Breadcrumb :style="{margin: '16px 0'}">
					<BreadcrumbItem to="/">首页</BreadcrumbItem>
					<BreadcrumbItem v-if="$route.path=='/rateDetail'">回款率详情页</BreadcrumbItem>
					<BreadcrumbItem v-if="$route.path=='/settings'">项目配置</BreadcrumbItem>
					<BreadcrumbItem v-if="$route.path=='/kpi'">绩效管理</BreadcrumbItem>
					<BreadcrumbItem v-if="$route.path=='/manualSync'">手动同步</BreadcrumbItem>
				</Breadcrumb>
				<div  v-if="$route.path=='/'">
					<Button type="primary" style="margin-right: 10px" @click="$router.push({path:'/settings'})" v-if="roles.indexOf('xmpz')>=0">
						<Icon type="md-settings"></Icon>
						项目配置
					</Button>
					<Button type="primary" style="margin-right: 10px" @click="syncReport">
						<Icon type="md-sync"></Icon>
						同步报告
					</Button>
					<Button type="primary" style="margin-right: 10px" @click="$router.push({path:'/manualSync'})" v-if="roles.indexOf('sdtb')>=0">
						<Icon type="md-sync"></Icon>
						手动同步
					</Button>
					<Button type="primary" style="margin-right: 10px" @click="$router.push({path:'/kpi'})" v-if="roles.indexOf('jxgl')>=0">
						<Icon type="md-settings"></Icon>
						绩效管理
					</Button>
					<Dropdown trigger="click" @on-click="download">
						<Button type="primary">
							<Icon type="md-download"></Icon>
							下载报表
						</Button>
						<DropdownMenu slot="list">
							<DropdownItem name="1">项目进度表</DropdownItem>
							<DropdownItem name="2">项目回款率</DropdownItem>
							<DropdownItem name="3">项目跟踪总览</DropdownItem>
							<DropdownItem name="4">项目应收表</DropdownItem>
							<DropdownItem name="5">项目全过程管理表</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
			<Content>
				<router-view/>
			</Content>
			<!-- <Drawer title="系统提醒" :closable="false" width="400" v-model="showDrawer">
				 <List v-if="warnItemNum>0||alertItemNum>0">
					<ListItem v-if="warnItemNum>0" >
						<ListItemMeta title="项目进度提醒" :description="`当前存在${warnItemNum}个进度异常的项目，请注意查看~`" />
					</ListItem>
					<ListItem v-if="alertItemNum>0">
						<ListItemMeta title="项目回款率提醒" :description="`当前存在${alertItemNum}个回款率异常的项目，请注意查看~`" />
					</ListItem>
				 </List>
				 <p v-else>暂无提醒事项</p>
			</Drawer> -->
		</Layout>
		<Footer class="footer-center">{{new Date().getFullYear()}} &copy; 上海丹龙建设工程有限公司-项目数据看板</Footer>
	</Layout>
	
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
	data(){
		return {
			showDrawer: false
		}
	},
	computed:{
        ...mapGetters(['warnItemNum','alertItemNum','phone','roles'])
    },

	methods:{
		logout(){
			this.$Modal.confirm({
				title: '提示',
				content: '<p>确定退出登录吗？</p>',
				onOk: () => {
					this.$cookie.remove('otoken');
					this.$router.push('/login');
				},
				onCancel: () => {
				}
			});
		},

		download(name){
			if(name==1){
				// 下载进度表
				this.$Modal.confirm({
					title: '提示',
					content: '<p>是否下载项目进度表？</p>',
					okText:'立即下载',
					onOk: () => {
						this.$http.exportExcel(`/dashboard/exportPj`).then(rs=>{
							console.log(rs)
							this.$common.exportBillingExcel(rs.data,rs)
						})
					},
					onCancel: () => {
					}
				});
				
			}else if(name==2){
				//下载回款率
				this.$Modal.confirm({
					title: '提示',
					content: '<p>是否下载项目回款率表？</p>',
					okText:'立即下载',
					onOk: () => {
						this.$http.exportExcel(`/dashboard/exportHkl`).then(rs=>{
							console.log(rs)
							this.$common.exportBillingExcel(rs.data,rs)
						})
					},
					onCancel: () => {
					}
				});
			}else if(name==3){
				//下载回款率
				this.$Modal.confirm({
					title: '提示',
					content: '<p>是否下载项目跟踪总览表？</p>',
					okText:'立即下载',
					onOk: () => {
						this.$http.exportExcel(`/dashboard/exportTrack`).then(rs=>{
							console.log(rs)
							this.$common.exportBillingExcel(rs.data,rs)
						})
					},
					onCancel: () => {
					}
				});
			}else if(name==4){
				//下载回款率
				this.$Modal.confirm({
					title: '提示',
					content: '<p>是否下载项目应收表？</p>',
					okText:'立即下载',
					onOk: () => {
						this.$http.exportExcel(`/dashboard/exportReceivable`).then(rs=>{
							console.log(rs)
							this.$common.exportBillingExcel(rs.data,rs)
						})
					},
					onCancel: () => {
					}
				});
			}else if(name==5){
				//下载回款率
				this.$Modal.confirm({
					title: '提示',
					content: '<p>是否下载项目全过程管理表？</p>',
					okText:'立即下载',
					onOk: () => {
						this.$http.exportExcel(`/dashboard/exportProjectWholeProcess`).then(rs=>{
							console.log(rs)
							this.$common.exportBillingExcel(rs.data,rs)
						})
					},
					onCancel: () => {
					}
				});
			}
		},

		syncReport() {
			this.$Modal.confirm({
				title: '提示',
				content: '<p>是否开始同步工程检测报告？</p>',
				okText:'开始同步',
				onOk: () => {
					this.$Message.info("正在同步，请耐心等待...");
					this.$http.get(`/dashboard/syncWriteBackTestReport`).then(rs=>{
						console.log(rs)
						this.$Message.success("同步成功，请至红圈刷新查看");
					})
				},
				onCancel: () => {
				}
			});
		}
	}
}
</script>

<style>
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html,body,#app{
	width: 100%;
	height: 100%;
}
.main-wrap{
	height: 100%;
}
.core-wrap{
	padding: 70px 50px 0 50px;
}
.header-bar{
	display: flex;
	justify-content: space-between;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99;
	background-color: #ffffff !important;
	box-shadow: 0 2px 4px #cccccc;
}
.header-bar .logo{
    width: 100px;
    height: 100%;
    background: url("./assets/logo.png") center no-repeat;
	background-size: contain;
}
/* .header-bar .nav-box{
	width: 100px;
    display: flex;
    justify-content: space-between;
} */
.header-bar .nav-item{
	width: 40px;
	text-align: center;
	/* color: #ffffff; */
}
.header-bar .ivu-icon{
	display: inline-block;
	cursor: pointer;
}
.header-bar .ivu-icon:hover{
	transform: rotate(1turn);
	transition: .3s;
}
.header-bar .ivu-badge{
	line-height: 20px !important;
}
.footer-center{
    text-align: center;
}
</style>
