import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        warnItemNum: 0,
        alertItemNum: 0,
        rateItemArr: [],
        phone: "",
        roles: "",
    },
    mutations: {
        setWarnItemNum(state, warnItemNum) {
            state.warnItemNum = warnItemNum
        },
        setAlertItemNum(state, alertItemNum) {
            state.alertItemNum = alertItemNum
        },
        setRateItemArr(state, rateItemArr) {
            state.rateItemArr = rateItemArr
        },
        setPhone(state, phone) {
            state.phone = phone
        },
        setRoles(state, roles) {
            state.roles = roles
        }
    },
    actions: {

    },
    getters: {
        warnItemNum(state) {
            return state.warnItemNum;
        },
        alertItemNum(state) {
            return state.alertItemNum;
        },
        rateItemArr(state) {
            return state.rateItemArr;
        },
        phone(state) {
            return state.phone;
        },
        roles(state) {
            return state.roles;
        }
    }
})